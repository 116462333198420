import {ref} from '@vue/composition-api';
import store from '@/store/index';

const useOfficialsPg = () => {
  // base data
  const officialsPg = ref (null);
  const loading = ref (false);

  // methods
  const getOfficialsPg = async () => {
    loading.value = true;
    const {data, status} = await store.dispatch ('fetchOfficialsPg');
    loading.value = false;
    if (status != 200) return;
    officialsPg.value = data;
    return data;
  };

  const updateOfficialsPg = async officialsPg => {
    loading.value = true;
    const {data, status} = await store.dispatch (
      'updateOfficialsPg',
      officialsPg
    );
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    officialsPg,
    loading,
    // methods
    getOfficialsPg,
    updateOfficialsPg,
  };
};

export default useOfficialsPg;
