var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('div',{staticClass:"row-title-btn"},[_c('div',{staticClass:"controls-actions"},[_c('div',{staticClass:"searcher-control"},[_c('div',{class:("input-control " + (_vm.searchActive ? 'active' : ''))},[_c('v-text-field',{staticClass:"app-bar-search flex-grow-0",attrs:{"rounded":"","dense":"","outlined":"","prepend-inner-icon":_vm.icons.mdiMagnify,"hide-details":""},on:{"focus":function($event){return _vm.changeSearch(true)},"keyup":function($event){return _vm.changeSearchM(_vm.keyword)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchActive),expression:"searchActive"}],attrs:{"icon":""},on:{"click":function () {
                _vm.changeSearch(false)
                _vm.changeSearchM(_vm.keyword)
              }}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)],1)]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.searchActive),expression:"!searchActive"}],staticClass:"ml-2",attrs:{"icon":""},on:{"click":_vm.openDialog}},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1)],1)]),_c('v-data-table',{staticClass:"table-rounded datatable-height elevation-3",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.officials,"loading":_vm.loading,"item-key":"name","fixed-header":"","disable-sort":""},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":props.items,"tag":"tbody","handle":".dragg-item","animation":200,"ghost-class":"ghost-drag-item"},on:{"end":_vm.onDropCallback}},_vm._l((props.items),function(item,index){return _c('data-table-row-handler',{key:index,attrs:{"item":item,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.dragg",fn:function(ref){return [_c('v-btn',{staticClass:"action-btn dragg-item",attrs:{"title":"Mover","small":"","icon":""},on:{"click":function () {}}},[_c('svg',{staticStyle:{"width":"20px","height":"20px"},attrs:{"width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M4 22h-4v-4h4v4zm0-12h-4v4h4v-4zm0-8h-4v4h4v-4zm3 0v4h17v-4h-17zm0 12h17v-4h-17v4zm0 8h17v-4h-17v4z"}})])])]}},{key:"item.img_official",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"img-officer center-sub-items"},[_c('v-img',{attrs:{"src":_vm.loadImage(item.img_official),"alt":item.name,"draggable":"false","max-width":"100"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('CrudButtons',{on:{"edit":function($event){return _vm.openEdit(item)},"remove":function($event){return _vm.openRemove(item)}}})],1)]}}],null,true)})}),1)]}}])}),_c('WDialog',{attrs:{"width":"600","dialog":_vm.dialog,"closeDialog":_vm.closeDialog,"title":_vm.titleDialog,"subtitle":_vm.subtitleDialog},scopedSlots:_vm._u([{key:"form-dialog",fn:function(){return [(_vm.dialog)?_c('FormOfficials',{attrs:{"loading":_vm.loading,"officialEdited":_vm.officialEdited},on:{"beforeUpdate":_vm.updateOfficial,"beforeCreate":_vm.createOfficial}}):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }