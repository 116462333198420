<template>
  <v-card class="elevation-0">
    <div class="row-title-btn">
      <div class="controls-actions">
        <div class="searcher-control">
          <div :class="`input-control ${searchActive ? 'active' : ''}`">
            <v-text-field
              v-model="keyword"
              @focus="changeSearch(true)"
              @keyup="changeSearchM(keyword)"
              rounded
              dense
              outlined
              :prepend-inner-icon="icons.mdiMagnify"
              class="app-bar-search flex-grow-0"
              hide-details
            ></v-text-field>
            <v-btn
              @click="
                () => {
                  changeSearch(false)
                  changeSearchM(keyword)
                }
              "
              v-show="searchActive"
              icon
            >
              <v-icon size="22">{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </div>
        </div>
        <v-btn @click="openDialog" v-show="!searchActive" icon class="ml-2">
          <v-icon size="24">{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="officials"
      :loading="loading"
      item-key="name"
      class="table-rounded datatable-height elevation-3"
      fixed-header
      disable-sort
    >
      <template v-slot:body="props">
        <draggable
          :list="props.items"
          tag="tbody"
          handle=".dragg-item"
          :animation="200"
          ghost-class="ghost-drag-item"
          @end="onDropCallback"
        >
          <data-table-row-handler v-for="(item, index) in props.items" :key="index" :item="item" :headers="headers">
            <template #[`item.dragg`]="{}">
              <v-btn class="action-btn dragg-item" title="Mover" @click="() => {}" small icon>
                <svg style="width: 20px; height: 20px" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M4 22h-4v-4h4v4zm0-12h-4v4h4v-4zm0-8h-4v4h4v-4zm3 0v4h17v-4h-17zm0 12h17v-4h-17v4zm0 8h17v-4h-17v4z"
                  />
                </svg>
              </v-btn>
            </template>
            <!-- icon -->
            <template #[`item.img_official`]="{ item }">
              <div class="img-officer center-sub-items">
                <v-img :src="loadImage(item.img_official)" :alt="item.name" draggable="false" max-width="100">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <!-- <img draggable="false" :src="loadImage(item.img_official)" :alt="item.name" /> -->
              </div>
            </template>
            <!-- name -->
            <template #[`item.name`]="{ item }">
              <div class="d-flex flex-column">
                <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
                <!-- <small>{{ item.post }}</small> -->
              </div>
            </template>
            <template #[`item.actions`]="{ item }">
              <div class="d-flex">
                <CrudButtons @edit="openEdit(item)" @remove="openRemove(item)"></CrudButtons>
              </div>
            </template>
          </data-table-row-handler>
        </draggable>
      </template>
    </v-data-table>
    <WDialog width="600" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormOfficials
          v-if="dialog"
          :loading="loading"
          :officialEdited="officialEdited"
          @beforeUpdate="updateOfficial"
          @beforeCreate="createOfficial"
        ></FormOfficials>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import CrudButtons from '@/components/buttons/CrudButtons.vue'
import useOfficials from '@/composables/useOfficials'
import WDialog from '@/components/dialogs/WDialog.vue'
import FormOfficials from '@/components/forms/FormOfficials.vue'
import draggable from 'vuedraggable'
import DataTableRowHandler from '@/components/data-table/DataTableRowHandler.vue'
import { ref } from '@vue/composition-api'

import { mdiPlus, mdiMagnify, mdiClose } from '@mdi/js'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    CrudButtons,
    WDialog,
    FormOfficials,
    draggable,
    DataTableRowHandler,
  },
  setup() {
    const {
      dialog,
      officialEdited,
      officials,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearchM,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createOfficial,
      updateOfficial,
      onDropCallback,
    } = useOfficials()

    const searchActive = ref(false)
    const keyword = ref('')
    const clearSearch = () => {
      keyword.value = ''
    }

    return {
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiClose,
      },
      dialog,
      officials,
      search,
      loading,
      officialEdited,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearchM,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createOfficial,
      updateOfficial,
      onDropCallback,
      headers: [
        { text: '', value: 'dragg' },
        { text: 'IMAGEN', value: 'img_official' },
        { text: 'NOMBRE', value: 'name' },
        { text: 'CARGO', value: 'liability' },
        { text: 'FECHA REGISTRO', value: 'created_at' },
        { text: 'ULTIMA ACTUALIZACION', value: 'updated_at' },
        { text: '', value: 'actions', align: 'right' },
      ],
      searchActive,
      keyword,
      clearSearch,
      changeSearch(val) {
        if (!val) {
          clearSearch()
        }
        searchActive.value = val
      },
    }
  },
  methods: {
    loadImage(src) {
      return loadAPIImage(src)
    },
  },
}
</script>

<style lang="scss" scoped>
.img-officer {
  padding: 10px;
}

.img-officer img {
  max-width: 150px;
  max-height: 150px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-title-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  position: relative;
  min-height: 60px;
}
.input-control {
  width: 50px;
  max-height: 60px;
  height: 60px;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  top: 0;
  transition: all ease-in-out 0.25s;
  transform-origin: initial;
  .app-bar-search {
    width: 100%;
  }
}
.input-control.active {
  position: absolute;
  width: 100%;
  right: 0;
  z-index: 5;
  padding: 4px 16px;
  animation: searcher ease-in-out 0.25s;
}
@keyframes searcher {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.controls-actions {
  display: flex;
  align-items: center;
}
</style>
