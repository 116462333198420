import { ref, computed } from '@vue/composition-api'
import store from '@/store/index'
import { getNewOrderEl } from '@/utils/order'

const useOfficials = () => {
  // base data
  const dialog = ref(false)
  const officialEdited = ref(null)
  const officials = ref([])
  const search = ref('')
  const loading = ref(false)

  // computed properties
  const titleDialog = computed(() => (officialEdited.value ? 'Editar Funcionarios' : 'Agregar Funcionarios'))
  const subtitleDialog = computed(() =>
    officialEdited.value
      ? 'Rellene los campos correctamente para modificar los datos del Funcionario'
      : 'Rellene los campos correctamente para validar los datos del nuevo Funcionario',
  )

  const closeDialog = () => {
    dialog.value = false
    officialEdited.value = null
  }

  // methods
  const getOfficialList = async () => {
    const { data, status } = await store.dispatch('fetchOfficials')
    if (status != 200) return
    officials.value = data
  }

  const createOfficial = async official => {
    loading.value = true
    const { data, status } = await store.dispatch('createOfficials', official)
    loading.value = false
    if (status != 200 && status != 201) return
    await getOfficialList()
    closeDialog()
  }

  const updateOfficial = async official => {
    loading.value = true
    const { data, status } = await store.dispatch('updateOfficials', official)
    loading.value = false
    if (status != 200 && status != 201) return
    await getOfficialList()
    closeDialog()
  }

  const removeOfficial = async official => {
    const val = await store.dispatch('generateConfirmDialog', {})
    if (!val) return
    const { data, status } = await store.dispatch('removeOfficials', official)
    if (status != 200 && status != 201 && status != 204) return
    await getOfficialList()
  }

  const handleSort = (a, b) => parseFloat(a.order) - parseFloat(b.order) // asc sort

  const sortOfficialsList = () => {
    officials.value.sort(handleSort)
  }

  const updateOrderOnList = obj => {
    const idx = officials.value.findIndex(item => item.id == obj.id)
    if (idx > -1) {
      officials.value.splice(idx, 1, obj)
    }
    sortOfficialsList()
  }

  const onDropCallback = async ev => {
    try {
      const orderObj = getNewOrderEl(ev, officials.value, 'asc')
      if (!orderObj) return
      const { data, status } = await store.dispatch('updateOfficials', orderObj)
      if (status == 200 || status != 201) {
        updateOrderOnList(data)
        return
      }
      sortOfficialsList()
    } catch (error) {
      sortOfficialsList()
    }
  }

  const openEdit = (official) => {
    officialEdited.value = { ...official }
    dialog.value = true
  }

  getOfficialList()

  return {
    dialog,
    officialEdited,
    officials,
    search,
    loading,
    // computed
    titleDialog,
    subtitleDialog,
    // methods
    changeSearchM(ev) {
      search.value = ev
    },
    openDialog() {
      dialog.value = true
    },
    openEdit,
    openRemove: removeOfficial,
    closeDialog,
    createOfficial,
    updateOfficial,
    onDropCallback,
  }
}

export default useOfficials
