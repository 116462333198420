export const getNewOrderEl = (ev = {}, itemsProps = [], type = 'desc') => {

  const isDecreasing = type == 'desc'

  // reordena la lista para buscar los elementos exactos antes de ser guardados
  const items = [...itemsProps].sort((a, b) => {
    return isDecreasing ? parseFloat(b.order) - parseFloat(a.order) : parseFloat(a.order) - parseFloat(b.order)
  })

    const { newIndex, oldIndex } = ev

    if (oldIndex == newIndex) return null // no movió nada

    const jumpNumber = 512
    const dragEl = items[oldIndex]
    const prevEl = items[newIndex - 1]
    const currEl = items[newIndex]
    const nextEl = items[newIndex + 1]
    const isDragTop = oldIndex > newIndex // arrastró hacia arriba
    let newOrderNumber = 0

    if (!prevEl) {
      newOrderNumber = isDecreasing ? parseFloat(currEl.order) + jumpNumber : parseFloat(currEl.order) - jumpNumber
    } else if (!nextEl) {
        newOrderNumber = parseFloat(currEl.order) - 512 // se resta porque está en forma desc
    } else {
      newOrderNumber = isDecreasing ? parseFloat(currEl.order) - jumpNumber : parseFloat(currEl.order) + jumpNumber
    }
    return {
        ...dragEl,
        order: newOrderNumber,
    }
}
