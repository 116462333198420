<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formOfficer" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="name">Nombre del Funcionario</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="name"
              v-model="officer.name"
              outlined
              dense
              placeholder="Funcionario"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="file">Imagen del Funcionario</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="file"
              v-model="image"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>

          <v-col v-if="url || officialEdited" cols="12" class="pb-0">
            <div class="img-officer center-sub-items">
              <img draggable="false" :src="url || loadImage(officer.img_official)" :alt="officer.name" />
            </div>
          </v-col>

          <v-col cols="12" md="3">
            <label for="liability">Cargo</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-textarea
              id="liability"
              v-model="officer.liability"
              outlined
              dense
              placeholder="Cargo del Funcionario"
              rows="3"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="3">
            <label for="email">Correo Electrónico</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="email"
              v-model="officer.email"
              outlined
              dense
              placeholder="Correo Electrónico del Funcionario"
              :rules="isEmail"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="telephone">Teléfono</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="telephone"
              v-model="officer.telephone"
              outlined
              dense
              placeholder="Teléfono del Funcionario"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="annexed">Anexo</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="annexed"
              v-model="officer.annexed"
              outlined
              dense
              placeholder="Anexo del Funcionario"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="linkedin">URL Linkedin</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="linkedin"
              v-model="officer.linkedin"
              outlined
              dense
              placeholder="Linkedin del Funcionario"
              :rules="isURL"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="transparency">URL Portal de Transparencia</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="transparency"
              v-model="officer.transparency"
              outlined
              dense
              placeholder="Portal de Transparencia del Funcionario"
              :rules="isURL"
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'
export default {
  components: {
    FormTemplate,
  },
  props: {
    officialEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      isEmail: [v => !v || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Correo electrónico inválido'],
      isURL: [
        v =>
          !v ||
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            v,
          ) ||
          'El URL debe tener https:// o http://',
      ],
      url: '',
      image: null,
      officer: this.officialEdited
        ? { ...this.officialEdited }
        : {
            text: '',
            urlText: '',
            description: '',
          },
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formOfficer.validate()
    },
    reset() {
      this.officer.icon = null
      this.officer.text = ''
      this.officer.urlText = ''
      this.officer.description = ''
      this.$refs.formOfficer.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      if (this.officialEdited) {
        let DTO = { ...this.officer }

        if (this.image) {
          DTO = {
            ...DTO,
            file: this.image,
          }
        }
        // // console.log('actualizar', DTO)
        this.$emit('beforeUpdate', DTO)
      } else {
        const DTO = { ...this.officer, file: this.image }
        // // console.log('crear', DTO)
        this.$emit('beforeCreate', DTO)
      }
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
  },
}
</script>

<style lang="scss" scoped>
.img-officer {
  padding: 10px;
}

.img-officer img {
  max-width: 200px;
  max-height: 200px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
