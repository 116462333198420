<template>
  <v-card>
    <OfficialsPgHeader></OfficialsPgHeader>
    <section class="form-full-width">
      <FormOfficialsPg :loading="loading" :getOfficialsPg="getOfficialsPg" @beforeUpdate="updateOfficialsPg"></FormOfficialsPg>
    </section>
  </v-card>
</template>

<script>
import FormOfficialsPg from '@/components/forms/FormOfficialsPg.vue'
import OfficialsPgHeader from './OfficialsPgHeader.vue'
import useOfficialsPg from '@/composables/useOfficialsPg'

export default {
  components: {
    FormOfficialsPg,
    OfficialsPgHeader,
  },
  setup() {
    // Composition API
    const {
      officialsPg,
      loading,
      // methods
      getOfficialsPg,
      updateOfficialsPg,
    } = useOfficialsPg()

    return {
      officialsPg,
      loading,
      // methods
      getOfficialsPg,
      updateOfficialsPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
